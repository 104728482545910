import React from "react";
import ThankYou from "../../../page-components/ThankYou";

export default function ThankYouPage() {
  return (
    <div className="mt-24">
      <ThankYou />
    </div>
  );
}
